export const Emails = [
  "surendra.karandikar@prorigosoftware.com",
  "amit.sinha@prorigosoftware.com",
  "rahul.lakhangaonkar@prorigosoftware.com",
  "snehal.ghute@prorigosoftware.com",
  "rajkishan.sundararajan@prorigosoftware.com",
  "aparna.jadhav@prorigosoftware.com",
  "gurushant.birajdar@prorigosoftware.com",
  "bhalchandra.lele@prorigosoftware.com",
  "vinaykumar.maladkar@prorigosoftware.com",
  "priyanka.mahajan@prorigosoftware.com",
  "suyash.sangar@prorigosoftware.com",
  "sujata.nandurdikar@prorigosoftware.com",
  "sachin.u.jadhav@prorigosoftware.com",
  "aditya.kawat@prorigosoftware.com",
  "kishore.kumar@prorigosoftware.com",
  "nayan.dhadd@prorigosoftware.com",
  "ganapati.sarkar@prorigosoftware.com",
  "deepali.lingayat@prorigosoftware.com",
  "shital.seware@prorigosoftware.com",
  "vedang.mahajan@prorigosoftware.com",
  "arati.sayaji@prorigosoftware.com",
  "kaustubh.karandikar@prorigosoftware.com",
  "ganesh.punde@prorigosoftware.com",
  "amruta.shelar@prorigosoftware.com",
  "avinash.mohanta@prorigosoftware.com",
  "madhav.varma@prorigosoftware.com",
  "abhijeet.deshmukh@prorigosoftware.com",
  "suzanne.christian@prorigosoftware.com",
  "gauri.gujar@prorigosoftware.com",
  "mohammad.wasi@prorigosoftware.com",
  "priyanka.jadhav@prorigosoftware.com",
  "vaishali.gawali@prorigosoftware.com",
  "ravi.sahu@prorigosoftware.com",
  "ankhiya.shah@prorigosoftware.com",
  "sanjay.renake@prorigosoftware.com",
  "vishal.kolhe@prorigosoftware.com",
  "girish.porje@prorigosoftware.com",
  "shubham.jambhulkar@prorigosoftware.com",
  "diwakar.sharma@prorigosoftware.com",
  "vikrant.parab@prorigosoftware.com",
  "ravi.kushavaha@prorigosoftware.com",
  "priyanka.naikwadi@prorigosoftware.com",
  "vinay.kumar@prorigosoftware.com",
  "rutuja.shinde@prorigosoftware.com",
  "pratik.rathi@prorigosoftware.com",
  "sanket.sanklecha@prorigosoftware.com",
  "chinmay.ghute@prorigosoftware.com",
  "swapna.kedar@prorigosoftware.com",
  "sourav.swain@prorigosoftware.com",
  "shatabdi.rana@prorigosoftware.com",
  "aditya.kulkarni@prorigosoftware.com",
  "swapnil.purigosavi@prorigosoftware.com",
  "sagar.kulthe@prorigosoftware.com",
  "megha.meshram@prorigosoftware.com",
  "mayur.sangvikar@prorigosoftware.com",
  "sachin.sadafale@prorigosoftware.com",
  "renuka.chaudhari@prorigosoftware.com",
  "shivani.katte@prorigosoftware.com",
  "rupesh.kumar@prorigosoftware.com",
  "nikhil.kharbas@prorigosoftware.com",
  "nilesh.vetal@prorigosoftware.com",
  "amita.vadhavkar@prorigosoftware.com",
  "aniket.patange@prorigosoftware.com",
  "aditi.wagh@prorigosoftware.com",
  "arti.pawde@prorigosoftware.com",
  "shubham.pawar@prorigosoftware.com",
  "kalyan.lysetti@prorigosoftware.com",
  "abhishek.lavhale@prorigosoftware.com",
  "sandesh.lande@prorigosoftware.com",
  "asmita.khedkar@prorigosoftware.com",
  "karishma.ugalmugale@prorigosoftware.com",
  "amit.kunkekar@prorigosoftware.com",
  "mandar.phadke@prorigosoftware.com",
  "sneha.phatak@prorigosoftware.com",
  "nupesh.borade@prorigosoftware.com",
  "prashant.zirmite@prorigosoftware.com",
  "nitesh.gandole@prorigosoftware.com",
  "basabdatta.majumder@prorigosoftware.com",
  "suraj.kale@prorigosoftware.com",
  "sushil.doke@prorigosoftware.com",
  "sumit.pawar@prorigosoftware.com",
  "madhuri.vahadane@prorigosoftware.com",
  "yogeshwari.baviskar@prorigosoftware.com",
  "sayali.kandhare@prorigosoftware.com",
  "shubham.kulkarni@prorigosoftware.com",
  "rajashree.newaskar@prorigosoftware.com",
  "omprakash.jangid@prorigosoftware.com",
  "yogesh.kudalkar@prorigosoftware.com",
  "jyoti.bhalwankar@prorigosoftware.com",
  "neha.alkunte@prorigosoftware.com",
  "pooja.dolas@prorigosoftware.com",
  "kiran.khadd@prorigosoftware.com",
  "sandeep.verma@prorigosoftware.com",
  "amasidda.biradar@prorigosoftware.com",
  "kedar.wale@prorigosoftware.com",
  "namrata.shah@prorigosoftware.com",
  "priyal.reja@prorigosoftware.com",
  "abhijit.jadhav@prorigosoftware.com",
  "sonam.patil@prorigosoftware.com",
  "kalyani.wankhade@prorigosoftware.com",
  "harish.nawale@prorigosoftware.com",
  "shubham.suryawanshi@prorigosoftware.com",
  "manoj.vairagkar@prorigosoftware.com",
  "mahesh.tambde@prorigosoftware.com",
  "sanjay.kulkarni@prorigosoftware.com",
  "kalpesh.wani@prorigosoftware.com",
  "ashvini.gadkari@prorigosoftware.com",
  "rucha.bhalerao@prorigosoftware.com",
  "vishakha.kunjir@prorigosoftware.com",
  "amey.ranade@prorigosoftware.com",
  "jagruti.pardeshi@prorigosoftware.com",
  "mayuri.kenjalkar@prorigosoftware.com",
  "rishabh.pandey@prorigosoftware.com",
  "sushmita.kale@prorigosoftware.com",
  "ganesh.dhake@prorigosoftware.com",
  "akash.mandvekar@prorigosoftware.com",
  "kaustubh.kapadne@prorigosoftware.com",
  "abhishek.lande@prorigosoftware.com",
  "palash.fulambarkar@prorigosoftware.com",
  "atharva.karyakarte@prorigosoftware.com",
  "komal.paluskar@prorigosoftware.com",
  "nikita.nashikakar@prorigosoftware.com",
  "arti.arote@prorigosoftware.com",
  "vaibhav.ghule@prorigosoftware.com",
  "tanmayee.salunke@prorigosoftware.com",
  "ashutosh.patil@prorigosoftware.com",
  "sushrut.deore@prorigosoftware.com",
  "hrishikesh.ratnaparkhi@prorigosoftware.com",
  "vaishnavi.pawar@prorigosoftware.com",
  "sarang.kamle@prorigosoftware.com",
  "swapnil.nawale@prorigosoftware.com",
  "sourabh.gangurde@prorigosoftware.com",
  "sandip.ahire@prorigosoftware.com",
  "ashish.damahe@prorigosoftware.com",
  "rahul.arote@prorigosoftware.com",
  "chandan.kumar@prorigosoftware.com",
  "manish.verma@prorigosoftware.com",
  "neeraj.tripathi@prorigosoftware.com",
  "akshay.mote@prorigosoftware.com",
  "gauri.pawar@prorigosoftware.com",
  "abhijeet.r.shinde@prorigosoftware.com",
  "gauravi.talegaonkar@prorigosoftware.com",
  "shubhangi.kalane@prorigosoftware.com",
  "prajakta.sondkar@prorigosoftware.com",
  "priyanka.mhetre@prorigosoftware.com",
  "ankita.arote@prorigosoftware.com",
  "jyoti.marathe@prorigosoftware.com",
  "parshuram.patil@prorigosoftware.com",
  "abhijeet.shinde@prorigosoftware.com",
  "sarika.jadhav@prorigosoftware.com",
  "soham.wavare@prorigosoftware.com",
  "shreyas.basutkar@prorigosoftware.com",
  "vivian.christian@prorigosoftware.com",
  "adarsh.avhale@prorigosoftware.com",
  "nikhil.kasar@prorigosoftware.com",
  "swayam.sonawane@prorigosoftware.com",
  "dattatraya.katkhade@prorigosoftware.com",
  "rohit.tak@prorigosoftware.com",
  "sakshi.baldwa@prorigosoftware.com",
  "aditya.rahane@prorigosoftware.com",
  "nikhil.khond@prorigosoftware.com",
  "ravikiran.bhanage@prorigosoftware.com",
  "vaidehi.patil@prorigosoftware.com",
  "aashutosh.chati@prorigosoftware.com",
  "akshay.kurumkar@prorigosoftware.com",
  "amol.a.jadhav@prorigosoftware.com",
  "devendra.jadhav@prorigosoftware.com",
  "dnyaneshwar.chikhale@prorigosoftware.com",
  "harshal.nirghude@prorigosoftware.com",
  "mohini.kawade@prorigosoftware.com",
  "munja.bodake@prorigosoftware.com",
  "pallavi.marathe@prorigosoftware.com",
  "priyanka.landge@prorigosoftware.com",
  "rameshwar.suryawanshi@prorigosoftware.com",
  "rushikesh.lawande@prorigosoftware.com",
  "tushar.ugale@prorigosoftware.com",
  "vaishnavi.tipugade@prorigosoftware.com",
  "priyanka.lal@prorigosoftware.com",
  "dinesh.narkhede@prorigosoftware.com",
  "sunil.kelkar@prorigosoftware.com",
  "navneet.kumar@prorigosoftware.com",
  "neelam.jaiswal@prorigosoftware.com",
  "priyanka.kadnor@prorigosoftware.com",
  "ratnaprabha.biradar@prorigosoftware.com",
  "ruchita.vispute@prorigosoftware.com",
  "haripriya.deore@prorigosoftware.com",
  "gayatri.kharmale@prorigosoftware.com",
  "snehal.kawade@prorigosoftware.com",
  "sayali.shukla@prorigosoftware.com",
  "ketan.haridas@prorigosoftware.com",
  "chaitrali.tarate@prorigosoftware.com",
  "prashant.thite@prorigosoftware.com",
  "prathamesh.dixit@prorigosoftware.com",
  "ajay.sonawane@prorigosoftware.com",
  "ankita.shinde@prorigosoftware.com",
  "gaurav.sutar@prorigosoftware.com",
  "shweta.sapkal@prorigosoftware.com",
  "pooja.patil@prorigosoftware.com",
  "kiran.sonwane@prorigosoftware.com",
  "kirti.jadhav@prorigosoftware.com",
  "pratiksha.thorat@prorigosoftware.com",
  "yash.deshpande@prorigosoftware.com",
  "yogita.kulkarni@prorigosoftware.com",
  "aditya.pawar@prorigosoftware.com ",
  "nikhil.sawant@prorigosoftware.com",
  "rohit.kulkarni@prorigosoftware.com",
  "sourabh.raj@prorigosoftware.com",
  "suraj.mate@prorigosoftware.com",
  "pratik.kushire@prorigosoftware.com",
  "omkar.shirke@prorigosoftware.com",
  "shruti.degaonkar@prorigosoftware.com",
  "akshay.borole@prorigosoftware.com",
  "payal.kachole@prorigosoftware.com",
  "sapana.shewale@prorigosoftware.com",
  "vaibhav.gandhre@prorigosoftware.com",
  "uddhav.jawarkar@prorigosoftware.com",
  "shubham.padole@prorigosoftware.com",
  "rupesh.palve@prorigosoftware.com",
  "dattaprasad.tambe@prorigosoftware.com",
  "ajinkya.bangar@prorigosoftware.com",
  "jyoti.s.marathe@prorigosoftware.com ",
  "sumit.khillare@prorigosoftware.com ",
  "amol.pawale@prorigosoftware.com",
  "manav.reja@prorigosoftware.com",
  "ganesh.nikam@prorigosoftware.com",
  "pankaj.raut@prorigosoftware.com",
  "sneha.bhad@prorigosoftware.com ",
  "shreya.bari@prorigosoftware.com ",
  "abhijit.kharat@prorigosoftware.com",
  "naman.patil@prorigosoftware.com",
  "gouri.patil@prorigosoftware.com",
  "shubham.bidkar@prorigosoftware.com",
  "shalaka.chitale@prorigosoftware.com",
  "aditi.desale@prorigosoftware.com",
  "prince.shaw@prorigosoftware.com",
  "rohini.gite@prorigosoftware.com",
  "abhishek.maheshwari@prorigosoftware.com",
  "abhijeet.birajdar@prorigosoftware.com",
  "dhanashri.chavan@prorigosoftware.com",
  "harshad.patil@prorigosoftware.com",
  "asawari.sarnobat@prorigosoftware.com",
  "tushar.adhav@prorigosoftware.com",
  "vedanti.khatale@prorigosoftware.com",
  "jaya.shinde@prorigosoftware.com",
  "shivani.shendre@prorigosoftware.com",
  "gayatri.yeole@prorigosoftware.com",
  "chinmay.borkar@prorigosoftware.com",
  "komal.mokashi@prorigosoftware.com",
  "uday.mantena@prorigosoftware.com ",
  "karan.ahir@prorigosoftware.com",
  "viplob.pratihar@prorigosoftware.com",
  "vamsinath.gadwal@prorigosoftware.com",
  "rohit.salunkhe@prorigosoftware.com",
  "prajakta.wagajkar@prorigosoftware.com",
  "nikhil.bhosale@prorigosoftware.com",
  "aishwarya.zende@prorigosoftware.com",
  "siddhi.dahale@prorigosoftware.com",
  "abhilash.parayil@prorigosoftware.com",
  "karan.saglani@prorigosoftware.com",
  "keerthi.avancha@prorigosoftware.com ",
  "vishal.warhade@prorigosoftware.com ",
  "sanika.baghel@prorigosoftware.com",
  "gautam.shejwal@prorigosoftware.com",
  "saurav.renake@prorigosoftware.com",
  "pooja.jadhav@prorigosoftware.com",
  "dhairyasheel.patil@prorigosoftware.com",
  "rohit.kale@prorigosoftware.com",
  "sanjyot.gaikwad@prorigosoftware.com",
  "siddharth.sarraf@prorigosoftware.com",
  "nilesh.dabhade@prorigosoftware.com",
  "ithelpdesk@prorigosoftware.com",
  "gitika.phatak@prorigosoftware.com",
  "abhishek.phulzalke@prorigosoftware.com",
  "kunal.lawande@prorigosoftware.com",
  "aditi.rawalekar@prorigosoftware.com",
  "chetan.mogali@prorigosoftware.com",
  "sunny.patil@prorigosoftware.com",
  "rutuja.kadane@prorigosoftware.com",
  "atharva.jagtap@prorigosoftware.com",
  "aishwarya.wagh@prorigosoftware.com",
  "apuroop.kodamanchili@prorigosoftware.com",
  "rajdeep.yadav@prorigosoftware.com",
  "satish.gangurde@prorigosoftware.com",
  "pratiksha.kharbas@prorigosoftware.com",
  "shreya.deshmukh@prorigosoftware.com",
  "shashank.chidipothu@prorigosoftware.com",
  "hemanth.bolla@prorigosoftware.com",
  "arnav.damle@prorigosoftware.com",
  "sumedh.dravid@prorigosoftware.com",
  "anjali.karpe@prorigosoftware.com",
  "paritosh.sanmane@prorigosoftware.com",
  "prachi.gholap@prorigosoftware.com",
  "shital.supekar@prorigosoftware.com",
  "omkar.bangar@prorigosoftware.com",
  "siddhant.keskar@prorigosoftware.com",
  "mayur.dhamale@prorigosoftware.com",
  "rushabh.keshatwar@prorigosoftware.com",
  "sanika.gadgil@prorigosoftware.com",
  "swarnima.khedgikar@prorigosoftware.com",
  "malay.divekar@prorigosoftware.com",
  "mandar.dhayagude@prorigosoftware.com",
  "amol.jawalkar@prorigosoftware.com",
  "sayali.davalbhakta@prorigosoftware.com",
  "siddhesh.jagtap@prorigosoftware.com",
  "suganya.raghunathan@prorigosoftware.com ",
  "sonali.bhalke@prorigosoftware.com",
  "akshit.patel@prorigosoftware.com"
]


export const Messages = [
  "Good Luck",
  "Another question is on the way",
  "Get Ready for another question",
  "Unlocking knowledge at the speed of thought",
  "Be ready. Be smart. Be noticed!",
];
