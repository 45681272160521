import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from "react";
import firebase from "../../../utils/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ResultList = () => {
  const classes = useStyles();

  const [results, setResults] = useState([]);

  useEffect(() => {
    const results = firebase.database().ref("Results");

    results.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      todoList.sort((a, b) => a.questionNo - b.questionNo)
      setResults(todoList);
    });
  }, []);

  const toggalVisibilty = (id, value) => {
    const qst = firebase.database().ref("Results").child(id);
    qst.update({
      isVisible: value,
    });
  };


  const deleteData = (id) => {
    const qst = firebase.database().ref("Results").child(id);
    qst.remove();
  }

  return (
    <List className={classes.root} style={{ width: "100%", maxWidth: "100%" }}>
      <ListItem
        role={undefined}
        dense
        button
      // onClick={}
      >
        <ListItemText
          primary={"Qst No."}
          style={{ width: "10%" }}
        />
        <ListItemText
          primary={"Correct Answer"}
          style={{ width: "40%" }}
        />
        <ListItemText
          primary={"Correct Count"}
          style={{ width: "20%" }}
        />
        <ListItemText
          primary={"Wrong Count"}
          style={{ width: "20%" }}
        />

        <ListItemText
          primary={"Total Count"}
          style={{ width: "20%" }}
        />
        {/* <ListItemText
              id={labelId}
              primary={new Date(result.date).toString()}
              style={{ fontWeight: index == 0 ? "bold" : "normal" }}
            /> */}
        <ListItemSecondaryAction>
          <VisibilityIcon

          />
          <DeleteIcon

          />
        </ListItemSecondaryAction>

      </ListItem>
      {results.map((result, index) => {
        const labelId = `checkbox-list-label-${index}`;

        return (
          <ListItem
            key={index}
            role={undefined}
            dense
            button
          // onClick={}
          >
            <ListItemText
              id={labelId}
              primary={result.questionNo}
              style={{ width: "10%" }}
            />
            <ListItemText
              id={labelId}
              primary={result.correctAnswer}
              style={{ width: "40%" }}
            />
            <ListItemText
              id={labelId}
              primary={result.correctAnswerCount}
              style={{ width: "20%" }}
            />
            <ListItemText
              id={labelId}
              primary={result.wrongAnswerCount}
              style={{ width: "20%" }}
            />

            <ListItemText
              id={labelId}
              primary={result.wrongAnswerCount + result.correctAnswerCount}
              style={{ width: "20%" }}
            />
            {/* <ListItemText
              id={labelId}
              primary={new Date(result.date).toString()}
              style={{ fontWeight: index == 0 ? "bold" : "normal" }}
            /> */}
            <ListItemSecondaryAction>
              {result.isVisible ? (
                <VisibilityIcon
                  onClick={() => {
                    toggalVisibilty(result.id, false);
                  }}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={() => {
                    toggalVisibilty(result.id, true);
                  }}
                />
              )}
              <DeleteIcon
                onClick={() => {
                  deleteData(result.id);
                }}
              />
            </ListItemSecondaryAction>

          </ListItem>
        );
      })}
    </List>
  );
};

export default ResultList;
