import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import firebase from "firebase";
import { StorageService } from "../../../../utils/storageService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Button color="inherit" href="/addQuestion">
              Add Question
            </Button>
            <Button color="inherit" href="/questionList">
              Question List
            </Button>
            <Button color="inherit" href="/results">
              Results
            </Button>
            <Button color="inherit" href="/resultsList">
              Results List
            </Button>
            <Button color="inherit" href="/quiz">
              Quiz
            </Button>
          </div>
          <div>
            <Button color="inherit" onClick={() => {
              firebase
                .auth().signOut().then(auth => {
                  StorageService.setItem("user", {})
                  window.location.reload();

                }).catch(error => {
                  alert("Failed to Logout")
                })
            }}>
              Logout
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
