import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import firebase from "../../../utils/firebase";

const QuestionView = ({ questionObj }) => {
  const [progress, setProgress] = useState(0);
  const alpha = ["A", "B", "C", "D"];

  const toggalVisibilty = (value) => {
    const qst = firebase.database().ref("Questions").child(questionObj.id);
    qst.update({
      isVisible: value,
    });

    if (value) {
      setTimer()
    }
  };

  const setTimer = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === (questionObj.timer + 3)) {
          clearInterval(timer);
          toggalVisibilty(false);
          return 0;
        }
        return oldProgress + 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  };

  return (
    <List key={questionObj.id}>
      <ListItem>
        <ListItemText style={{ width: "1%" }} primary={questionObj.qstNo} />
        <ListItemText
          style={{ width: "50%", wordWrap: "break-word" }}
          primary={questionObj.question}
          secondary={questionObj.answers.map((option, index) => <div style={{ margin: 5 }}><label>{alpha[index] + ")"}  {option}</label></div>)}
        />

        <ListItemText
          primary={alpha[questionObj.correctAnswerIndex] + ") " + questionObj.correctAnswer}
          secondary={"Points:" + questionObj.points + ", Time:" + questionObj.timer}
          style={{ width: "25%", margin: 5 }}
        />
        <ListItemText
          primary={progress}
          style={{ width: "5%", margin: 5 }}
        />
        {questionObj.isVisible ? (
          <VisibilityIcon
            onClick={() => {
              toggalVisibilty(false);
            }}
          />
        ) : (
          <VisibilityOffIcon
            onClick={() => {
              toggalVisibilty(true);
            }}
          />
        )}

      </ListItem>
    </List>
  );
};

export default QuestionView;
