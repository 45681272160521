export class StorageService {
  static setItem(key, value) {
    const stringValue = JSON.stringify(value);
    sessionStorage.setItem(key, stringValue);
  }

  static getItem(key) {
    let res = sessionStorage.getItem(key);
    return JSON.parse(res);
  }
}
