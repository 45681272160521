import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

const ProrigoLogo = () => {
  const [initialAnimation, setInitialAnimation] = useState(true);
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../../_content/lottie/Logo1.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const element = document.querySelector('.past-year');

    element && element.addEventListener('animationend', () => {
      setInitialAnimation(false)
    });
  }, [])

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Lottie options={lottieOptions} speed={1} />
    {/* <div style={{ display: "flex" }}>
        {
          initialAnimation
            //   ?
            //   // <label style={{ alignSelf: "center", fontWeight: "bold", color: "#2E3191", WebkitTextStrokeWidth: 2, WebkitTextStrokeColor: "#fff" }} className="animate__animated animate__hinge past-year animate__delay-2s year-num">14</label>
            //   <label style={{ alignSelf: "center", fontWeight: "bold", color: "#2E3191", WebkitTextStrokeWidth: 2, WebkitTextStrokeColor: "#fff" }} className="past-year year-num">14</label>
            //   :
            ?
            <label style={{ alignSelf: "center", fontWeight: "bold", color: "#2E3191", WebkitTextStrokeWidth: 2, WebkitTextStrokeColor: "#fff" }} className="animate__animated animate__backOutDown year-num past-year animate__delay-2s">15</label>
            :
            <label style={{ alignSelf: "center", fontWeight: "bold", color: "#2E3191", WebkitTextStrokeWidth: 2, WebkitTextStrokeColor: "#fff" }} className="animate__animated animate__bounceInDown year-num current-year">16</label>

        }
      </div> */}
    </div>
  );
};

export default ProrigoLogo;
