export class Question {
  question = "";
  answers = ["", "", "", ""];
  correctAnswer = "";
  timer = 30;
  isVisible = false;
  correctAnswerIndex = "";
  mediaUrl = "";
  points = 35;
}
