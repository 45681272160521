import React, { useEffect, useState } from "react";
import QuestionView from "../questionView/QuestionView";
import firebase from "../../../utils/firebase";

const QuestionViewList = ({ }) => {
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    const questions = firebase.database().ref("Questions");
    questions.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setQuestionList(todoList);
    });
  }, []);

  return (
    <>
      {questionList.map((item, index) => {
        return <QuestionView questionObj={item} key={index} />;
      })}
    </>
  );
};

export default QuestionViewList;
