import React, { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";

const Loader = ({ totalTime, onTimeUpdate = () => {} }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (totalTime) setTimer();
  }, [totalTime]);

  const setTimer = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === totalTime * 1000) {
          clearInterval(timer);
          return;
        }
        onTimeUpdate(oldProgress + 100);
        return oldProgress + 100;
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  };

  return (
    <LinearProgress
      variant={"determinate"}
      value={(progress / (totalTime * 1000)) * 100}
    />
  );
};

export default Loader;
