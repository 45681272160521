import React from "react";
import { Route } from "react-router-dom";
import QuizePage from "../../../client/components/quizPage";
import AddQuestions from "../addQuestions";
import Header from "../layout/header/Header";
import QuestionViewList from "../questionViewList";
import Results from "../results";
import ResultList from "../resultsList";

const Routes = () => {
  return (
    <>
      <Header />
      <Route exact path="/addQuestion" component={AddQuestions} />
      <Route exact path="/questionList" component={QuestionViewList} />
      <Route exact path="/results" component={Results} />
      <Route exact path="/resultsList" component={ResultList} />
      <Route exact path="/quiz" component={QuizePage} />
    </>
  );
};

export default Routes;
