import { Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { Emails } from "../../../Constants";
import firebase from "../../../utils/firebase";
import ProTextBox from "../input/textBox/TextBox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Results = () => {
  const classes = useStyles();

  const [results, setResults] = useState([]);
  const [users, setUsers] = useState([]);
  const [qstNo, setQstNo] = useState(1);
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
  const [wrongAnswerCount, setWrongAnswerCount] = useState(0);

  const getUserList = () => {
    const questions = firebase.database().ref("UserAnswers");

    questions.once("value", (snapshot) => {
      const todos = snapshot.val();
      let userList = [];
      snapshot.forEach((child) => {
        userList.push(child.key);
      });

      setUsers(userList);
    });
  };

  useEffect(() => {
    // getUserList();

    setUsers(Emails.map(a => a.replaceAll(".", "-")))

  }, []);

  const createResult = () => {
    const questions = firebase.database().ref("Results");
    questions.push({
      correctAnswer: results[0]?.correctAnswer,
      questionNo: qstNo,
      correctAnswerCount: correctAnswerCount,
      wrongAnswerCount: wrongAnswerCount,
      date: Date.now(),
      data: results,
      isVisible: false,
    });
    setQstNo(parseInt(qstNo) + 1);
  };

  const getResultForQuestion = () => {

    users.forEach((user) => {
      const questions = firebase.database().ref(`UserAnswers/${user}`);

      questions.orderByChild('questionNo').equalTo(parseInt(qstNo)).once("child_added", (snapshot) => {
        const todos = snapshot.val();
        setResults(oldResult => {
          return [...oldResult, { ...todos, name: user.replaceAll("-", ".") }];
        })
      });
    });
  };

  // useEffect(() => {
  //   if (users && users.length && results && !results.length) {
  //     getResultForQuestion();
  //   }
  // }, [users]);

  useEffect(() => {
    if (results && results.length) {
      setCorrectAnswerCount(results.filter(a => a.correctAnswer == a.answer).length)
      setWrongAnswerCount(results.filter(a => a.correctAnswer != a.answer).length)
    }
  }, [results]);


  const getResults = () => {
    if (users && users.length) {
      getResultForQuestion();
    }
  }

  return (
    <div>
      <div style={{ margin: 5, display: "flex" }}>
        <ProTextBox label={"Question Number"} defaultValue={qstNo} value={qstNo} isNumber={true} onValueChange={(value) => {
          setQstNo(value)
        }} />
        <Button variant="contained" color="primary" style={{ margin: "0 10px 0 10px" }} onClick={() => { setResults([]); getResults() }}>
          Get Results
        </Button>
        <Button variant="contained" color="primary" style={{ margin: "0 10px 0 10px" }} onClick={() => { setResults([]) }}>
          Clear Result
        </Button>
        <label style={{ fontSize: "2em", fontWeight: "bold" }}>{results && results.length}</label>
      </div>

      <List className={classes.root} style={{ width: "100%", maxWidth: "80%" }}>
        <ListItem
          role={undefined}
          dense
          button
        // onClick={}
        >
          <ListItemText
            primary={"Name"}
            style={{ width: "50%" }}
          />
          <ListItemText
            primary={"Qst No"}
            style={{ width: "10%" }}
          />
          <ListItemText
            primary={"Answer"}
            style={{ width: "40%" }}
          />

          <ListItemSecondaryAction>
            <ListItemText
              primary={"Points"}
              style={{ fontWeight: "bold" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        {results.map((result, index) => {
          const labelId = `checkbox-list-label-${index}`;

          return (
            <ListItem
              key={index}
              role={undefined}
              dense
              button
            // onClick={}
            >
              <ListItemText
                id={labelId}
                primary={result.name}
                style={{ width: "50%" }}
              />
              <ListItemText
                id={labelId}
                primary={result.questionNo}
                style={{ width: "10%" }}
              />
              <ListItemText
                id={labelId}
                primary={result.answer}
                style={{ width: "40%" }}
              />

              <ListItemSecondaryAction>
                <ListItemText
                  id={labelId}
                  primary={result.points}
                  style={{ fontWeight: index == 0 ? "bold" : "normal" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>

      <Button
        variant="contained"
        onClick={() => {
          createResult();
        }}
      >
        Create result
      </Button>
    </div>
  );
};

export default Results;
