import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { StorageService } from "../../../utils/storageService";

const ThankYou = () => {

    const lottieOptions = {
        autoplay: true,
        animationData: require("../../../_content/lottie/thankYou.json"),
    };

    useEffect(() => {
        setTimeout(() => {
            StorageService.setItem("user", {});
            window.open("/results", "_self");
        }, 6000)

    })


    return (
        <Lottie options={lottieOptions} speed={1} width={"100%"} />
    )
}

export default ThankYou;