import { Checkbox } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import React, { useEffect, useState } from "react";

const ProTextBox = ({
  label,
  defaultValue,
  defaultCheck,
  value,
  isOption,
  isNumber,
  onValueChange = () => { },
  onCheckBoxValueChange = () => { },
}) => {
  const [textValue, setTextValue] = useState(defaultValue);
  const [checked, setChecked] = useState(defaultCheck);

  const handleChange = (targetValue) => {
    setTextValue(targetValue);
    onValueChange(targetValue);
  };

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  return (
    <div style={{ display: "flex" }}>
      {isOption && (
        <Checkbox
          value={checked}
          name="options"
          onChange={(event, value) => {
            onCheckBoxValueChange(value);
            setChecked(value);
          }}
        />
      )}
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="component-outlined">{label}</InputLabel>
        <OutlinedInput
          type={isNumber ? "number" : "text"}
          id="component-outlined"
          value={textValue}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
          label={label}
        />
      </FormControl>
    </div>
  );
};

export default ProTextBox;
