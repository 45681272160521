import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import firebase from "../../../utils/firebase";
import { StorageService } from "../../../utils/storageService";
import party from "party-js";

const QuestionView = ({
  questionObj,
  elapsedTime,
  totalTime,
  isSubmitted = () => { },
}) => {
  const user = StorageService.getItem("user");

  const [disable, setDisable] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  const logAnswer = (option, index) => {
    setDisable(true);
    if (isAnswered) {
      isSubmitted(true);
      return;
    }

    const qst = firebase
      .database()
      .ref("UserAnswers")
      .child(user.email.replaceAll(".", "-"));

    const totalTimeMili = totalTime * 1000;

    const timer = elapsedTime ? elapsedTime : totalTimeMili;

    qst.push({
      answer: option,
      answerIndex: index,
      questionNo: questionObj.qstNo,
      qstId: questionObj.id,
      time: timer,
      correctAnswer: questionObj.correctAnswer,
      correctAnswerIndex: questionObj.correctAnswerIndex,
      points: index == questionObj.correctAnswerIndex ? questionObj.points : 0,
    });

    // if (index == questionObj.correctAnswerIndex) {
    //   party.confetti(document.getElementById("party"), {
    //     count: party.variation.range(20, 40000),
    //   });
    //   party.sparkles(document.getElementById("party"), {
    //     count: party.variation.range(20, 40000),
    //   });
    // }

    isSubmitted(true);
  };

  useEffect(() => {
    if (elapsedTime == totalTime * 1000) {
      isSubmitted(true);
    }
  }, [elapsedTime]);

  useEffect(() => {
    if (questionObj && questionObj.qstNo) {
      const qst = firebase
        .database()
        .ref("UserAnswers")
        .child(user.email.replaceAll(".", "-"));

      qst.orderByChild('questionNo').equalTo(parseInt(questionObj.qstNo)).once("child_added", (snapshot) => {
        const todos = snapshot.val();
        isSubmitted(true);
        setIsAnswered(true);
      });
    }
  }, [questionObj])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: questionObj &&
          questionObj.mediaUrl ? "space-between" : "center",
        flexDirection: "column",
        height: "100%",
      }}
      className="question-view"
    >
      <div>
        {
          questionObj &&
          questionObj.mediaUrl
          &&
          <img
            style={{
              alignSelf: "center", marginTop: "10px"
            }}
            src={questionObj && questionObj.mediaUrl}
            className="qst-img"
          />
        }
      </div>
      <div style={{ marginBottom: "20px" }} >
        <label style={{ fontSize: "25px", color: "#fff", }}>
          {questionObj && questionObj.question}
        </label>
        <div
          style={{
            width: "100%",
          }}
        >
          {questionObj &&
            questionObj.answers &&
            questionObj.answers.map((option, index) => {
              return (
                <Button
                  key={index}
                  disabled={disable || !elapsedTime}
                  variant="contained"
                  color="primary"
                  style={{
                    margin: 5, width: "40%", minHeight: 80,
                  }}
                  onClick={() => {
                    logAnswer(option, index);
                  }}
                >
                  {option}
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default QuestionView;
