import { CircularProgress } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import firebase from "firebase";
import React, { useState } from "react";
import { StorageService } from "../../../utils/storageService";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/prorigo-quiz-5f911.appspot.com/o/assets%2Fwhite%20full%20logo.svg?alt=media&token=5f4cb2d7-03a4-4142-b0b6-87729d2dab94)",
    backgroundColor: "#000",
    backgroundRepeat: "no-repeat",
    // backgroundColor:
    //   theme.palette.type === "light"
    //     ? theme.palette.grey[50]
    //     : theme.palette.grey[900],
    backgroundSize: "50%",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = () => {
  const classes = useStyles();

  const [credientials, setCredientials] = useState({ email: "", password: "" });
  const [isLoading, setLoading] = useState(false);

  const login = () => {
    if (!credientials.email.trim() || !credientials.password.trim()) {
      window.alert("Please enter valid details");
      return;
    }

    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(credientials.email.toLocaleLowerCase(), credientials.password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        StorageService.setItem("user", {
          email: user.email,
          uid: user.uid,
          refreshToken: user.refreshToken,
        });
        window.location.reload();
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setLoading(false);
        window.alert("Failed to SignIn, Please check credentials");
      });
  };

  const onKeyDown = (e) => {
    if (e.keyCode == 13) {
      login();
    }
  }

  const onResetPassword = () => {
    if (!credientials.email.trim()) {
      alert("Please enter email address!");
      return;
    }
    setLoading(true);

    firebase.auth().sendPasswordResetEmail(credientials.email).then((value) => {
      alert("Reset link sent successfully!")
    }).catch((error) => {
      error && error.code && error.code == "auth/user-not-found" ? alert("Invalid email address") : alert("Error occured while sending reset link");
      console.log(error)
    }).finally((message) => {
      setLoading(false);
    })
  }


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {
          isLoading
            ?
            <>
              <div style={{ width: "100%", height: "100%", top: 0, right: 0, left: 0, bottom: 0, zIndex: 2, backgroundColor: "rgba(0,0,0,0.5)", position: "absolute" }}></div>
              <div style={{ position: "relative", top: "50%" }}>
                <CircularProgress />
              </div>
            </>
            : null
        }
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={credientials.email}
              onChange={(event) => {
                setCredientials({
                  ...credientials,
                  email: event.target.value.trim(),
                });
              }}
              onKeyDown={onKeyDown}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={credientials.password}
              autoComplete="current-password"
              onChange={(event) => {
                setCredientials({
                  ...credientials,
                  password: event.target.value,
                });
              }}
              onKeyDown={onKeyDown}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <a style={{ fontSize: "1em", cursor: "pointer" }} onClick={() => { onResetPassword() }}>Reset Password</a>
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                login();
              }}
            >
              Sign In
            </Button>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid >
    </Grid >
  );
};

export default SignIn;
