import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from "react";
import firebase from "../../../utils/firebase";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        '& .whatAmI': {
            backgroundColor: '#a5e9f0',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .muhaavre': {
            backgroundColor: '#a5f0cc',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .food': {
            backgroundColor: '#a1d9ce',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .guessSong': {
            backgroundColor: '#F0C0A5',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .series': {
            backgroundColor: '#c0a5f0',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .movie': {
            backgroundColor: '#ff9e48',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .currentAff': {
            backgroundColor: '#c0a5f0',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .knowit': {
            backgroundColor: '#F0C0A5',
            color: '#1a3e72',
            fontWeight: '600',
        }
    },
});

export const ResultView = () => {
    const classes = useStyles();

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const getColors = (index) => {

        switch (true) {
            case index <= 6:
                return "whatAmI"
            case index > 6 && index <= 13:
                return "muhaavre"
            case index > 13 && index <= 18:
                return "food"
            case index > 18 && index <= 23:
                return "guessSong"
            case index > 23 && index <= 30:
                return "series"
            case index > 30 && index <= 40:
                return "movie"
            case index > 40 && index <= 50:
                return "currentAff"

            default:
                break;
        }
    }

    const getResults = () => {
        const results = firebase.database().ref("Results");
        results.on("value", (snapshot) => {
            const todos = snapshot.val();
            const todoList = [];

            for (let id in todos) {
                if (todos[id].isVisible) todoList.push({ id, ...todos[id] });
            }

            const rows = [];
            let mainObj = { id: 0, email: "Correct Answer" };
            let coloumnArray = [{ field: 'email', headerName: 'Email', width: 150 }, { field: 'total', headerName: 'Total Points', width: 150 }, { field: 'totalTime', headerName: 'Total Time', width: 150 }]

            for (let i = 0; i < todoList.length; i++) {
                mainObj[todoList[i].questionNo] = todoList[i].correctAnswer
                coloumnArray.push({ field: todoList[i].questionNo, headerName: "Qst " + todoList[i].questionNo, width: 150, cellClassName: getColors(todoList[i].questionNo), headerClassName: getColors(todoList[i].questionNo) })
            }

            setColumns(coloumnArray);

            rows.push(mainObj);

            const lengths = todoList && todoList.length && todoList.map(a => a.data.length);
            const heighestLengthIndex = todoList.length && lengths.indexOf(Math.max(...lengths));

            const userNames = todoList && todoList.length && todoList[heighestLengthIndex].data.map(a => a.name);

            let data = todoList && todoList.map(a => a.data)
            data = data && data.flat();
            for (let i = 0; i < userNames.length; i++) {
                let userObj = { id: i + 1, email: userNames[i] }
                userObj["totalTime"] = 0;
                userObj["total"] = 0;

                const userAnswer = data && data.filter(ans => ans.name == userNames[i]);

                for (let objValue in mainObj) {
                    if (mainObj[objValue] != 0 && mainObj[objValue] != "Correct Answer") {
                        let qstAnswer = userAnswer.find(a => a.correctAnswer == mainObj[objValue]);
                        userObj[objValue] = qstAnswer ? qstAnswer.points : 0
                        userObj["totalTime"] += qstAnswer ? (parseInt(qstAnswer.time) / 1000) : 0
                        userObj["total"] += qstAnswer ? parseInt(qstAnswer.points) : 0
                    }
                }

                userObj["totalTime"] = userObj["totalTime"].toFixed(2);
                rows.push(userObj)
            }

            rows.sort((a, b) => {
                return b.total - a.total || a.totalTime - b.totalTime;
            })

            setRows(rows)
        });
    };



    useEffect(() => {
        getResults();
    }, [])

    return (
        <>
            <div style={{ width: '100%', height: window.innerHeight }} className={classes.root}>
                {
                    rows && rows.length && rows.length > 1
                        ?
                        <DataGrid rows={rows} columns={columns} />
                        :
                        <h1>"Result's will be updated soon..."</h1>
                }
            </div>
        </>
    )

}

export default ResultView;