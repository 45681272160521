import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCCQYRo9S4Ht9M21-EyTVhKom3qGT2QEMQ",
    authDomain: "prorigo-quiz-5f911.firebaseapp.com",
    databaseURL: "https://prorigo-quiz-5f911-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "prorigo-quiz-5f911",
    storageBucket: "prorigo-quiz-5f911.appspot.com",
    messagingSenderId: "10236644600",
    appId: "1:10236644600:web:44b1899aa3ffe42790bf0d"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
