import "animate.css";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import Routes from "./admin/components/routes/Routes";
import "./App.css";
import ClientRoutes from "./client/components/clientRoutes/ClientRoutes";
import QuizePage from "./client/components/quizPage";
import { StorageService } from "./utils/storageService";
// import { usersData } from "./Constants";
import { useEffect } from "react";


const App = () => {
  const user = StorageService.getItem("user");
  const history = createBrowserHistory({ basename: "" });

  // useEffect(() => {
  //   for (const user of usersData) {
  //     debugger;
  //     firebase
  //       .auth().createUserWithEmailAndPassword(user.email, user.password)
  //       .then((userCredential) => {
  //         // Signed in 
  //         const user = userCredential.user;
  //         console.info("User created ", user);
  //         // ...
  //       })
  //       .catch((error) => {
  //         const errorCode = error.code;
  //         const errorMessage = error.message;
  //         // ..
  //       });
  //   }

  // }, [])

  return (
    <div className="App">
      {user && user.email && user.uid == "rCJYNVZLNFhxOzhMwB0n2rPff142" ? (
        <>
          <Router history={history} >
            <Routes />
          </Router>
        </>
      ) : user && user.uid && user.email ? (
        <QuizePage />
      ) : (
        <Router history={history} >
          <ClientRoutes />
        </Router>
      )}
    </div>
  );
};

export default App;
