import { Button, Input } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Question } from "../../../model/questions";
import firebase from "../../../utils/firebase";
import ProTextBox from "../input/textBox/TextBox";

const AddQuestions = ({ }) => {
  const [questionObj, setQuestionObj] = useState(new Question());
  const [count, setCount] = useState(0);
  const [image, setImage] = useState("");

  const handleUpload = () => {
    // console.log(this.state.image);
    if (!image) {
      createQuestion(null);
      return;
    }

    if (image && image.size > 500000) {
      window.alert("Image Size should be less then 50kb");
      document.getElementById("raised-button-file").value = null;
      return;
    }

    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = storageRef.child("Questions/" + image.name).put(image);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        var progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Progress: ", progress);
      },
      (error) => {
        throw error;
      },
      () => {
        // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{

        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          createQuestion(url);
        });
        document.getElementById("raised-button-file").value = null;
      }
    );
  };

  useEffect(() => {
    const questions = firebase.database().ref("Questions");
    questions.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setCount(todoList.length);
    });
  }, []);

  const onChange = (value, index) => {
    questionObj.answers[index] = value;
    setQuestionObj({
      ...questionObj,
      answers: questionObj.answers,
    });
  };

  const validateQuestion = () => {
    let isValid = true;

    if (!questionObj.question) isValid = false;

    if (isValid)
      questionObj.answers.forEach((ans) => {
        if (!ans) isValid = false;
      });

    if (!questionObj.correctAnswer) isValid = false;

    return isValid;
  };

  const createQuestion = (mediaUrl) => {
    if (!validateQuestion()) {
      window.alert("Please provide value to all fields");
      return;
    }
    const questions = firebase.database().ref("Questions");

    questions.push({
      ...questionObj,
      qstNo: count + 1,
      mediaUrl: questionObj.mediaUrl ? questionObj.mediaUrl : mediaUrl,
    });
    setQuestionObj(new Question());
    window.location.reload();
  };

  return (
    <div>
      <div style={{ margin: 5, width: "100%" }}>
        <ProTextBox
          defaultValue={""}
          label={"Question"}
          value={questionObj.question}
          onValueChange={(value) => {
            setQuestionObj({ ...questionObj, question: value });
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
        {questionObj.answers.map((option, index) => {
          return (
            <div style={{ marginLeft: index != 0 ? 5 : 0, width: "100%" }}>
              <ProTextBox
                isOption={true}
                defaultCheck={false}
                defaultValue={""}
                value={option}
                label={"Option " + index}
                onValueChange={(value) => {
                  onChange(value, index);
                }}
                onCheckBoxValueChange={(value) => {
                  setQuestionObj({
                    ...questionObj,
                    correctAnswerIndex: index,
                    correctAnswer: questionObj.answers[index],
                  });
                }}
              />
            </div>
          );
        })}
      </div>
      <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
        <div style={{ marginLeft: 5, width: "100%" }}>
          <ProTextBox
            defaultValue={questionObj.timer}
            value={questionObj.timer}
            label={"Timer in sec"}
            onValueChange={(value) => {
              setQuestionObj({ ...questionObj, timer: parseInt(value) });
            }}
          />
        </div>
        <div style={{ marginLeft: 5, width: "100%" }}>
          <ProTextBox
            defaultValue={questionObj.points}
            value={questionObj.points}
            label={"Points"}
            onValueChange={(value) => {
              setQuestionObj({ ...questionObj, points: value });
            }}
          />
        </div>
        <div style={{ marginLeft: 5, width: "100%" }}>
          <ProTextBox
            defaultValue={questionObj.mediaUrl}
            value={questionObj.mediaUrl}
            label={"Media Url"}
            onValueChange={(value) => {
              setQuestionObj({ ...questionObj, mediaUrl: value });
            }}
          />
        </div>
      </div>
      <div
        style={{
          margin: 5,
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button variant="contained" color="primary" onClick={handleUpload}>
          Add Question
        </Button>
        <Input
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          accept=".png, .jpeg"
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" color="secondary" component="span">
            Upload
          </Button>
        </label>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            let data = new Question();
            setQuestionObj(data);
          }}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};

export default AddQuestions;
