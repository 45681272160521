import {
  Button,
  CssBaseline,
  Grid, makeStyles
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import BackgroundSlider from 'react-background-slider';
import { Messages } from "../../../Constants";
import firebase from "../../../utils/firebase";
import Loader from "../loader/Loader";
import ProrigoLogo from "../logo/Logo";
import ThankYou from "../logo/ThankYou";
import QuestionView from "../questionView";
import party from "party-js";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  color: {
    backgroundColor: "rgba(0,0,0,0.2)"
  }
}));

const QuizePage = () => {
  const lastQst = "51";
  const classes = useStyles();
  const [question, setQuestion] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(100);
  const [showLogo, setShowLogo] = useState(true);
  const [quizStarted, setQuizStarted] = useState(false);
  const [message, setMessage] = useState("Get ready Quiz will start soon...");
  const [audio] = useState(new Audio("https://rbstudios.sgp1.cdn.digitaloceanspaces.com/prorigo/quiz/assets/music/kbc.mp3"));
  const [isLastQuestion, setIslastQuestion] = useState(false);
  const [imagePaths, setImagePaths] = useState([]);

  audio.volume = 0.5;

  useEffect(() => {
    getQuestion();
  }, []);

  const getQuestion = () => {
    const questions = firebase.database().ref("Questions");
    questions.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        if (todos[id].isVisible) todoList.push({ id, ...todos[id] });
      }
      setQuestion(todoList[0]);
      if (todoList.length) {
        setQuizStarted(true);
        setShowLogo(false);
      } else {
        setShowLogo(true);
      }
    });
  };

  const partyPoppers = () => {

    party.confetti(document.getElementById("party"), {
      count: party.variation.range(20, 4000),
      speed: party.variation.range(50, 2000),
    });

    party.sparkles(document.getElementById("party"), {
      count: party.variation.range(20, 4000),
      speed: party.variation.range(50, 2000),
    });
  }

  useEffect(() => {
    if (question && question.qstNo) {
      let num = Math.floor(Math.random() * Messages.length);
      setMessage(Messages[num]);
      question.qstNo != lastQst && audio.play();
      question.qstNo == lastQst && partyPoppers();
      setIslastQuestion(question.qstNo == lastQst);
    }
    // const threePath = [];
    // for (let index = 1; index <= 50; index++) {
    //   threePath.push("https://sgp1.digitaloceanspaces.com/rbstudios/prorigo/quiz/event-images/" + index + ".jpg");
    // }
    // setImagePaths(threePath);
  }, [question])

  return (
    <Grid container component="main" className={classes.root} id="party" style={{backgroundColor:"#000"}} >
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className={classes.color}>
        {showLogo ?
          <>
            <BackgroundSlider
              images={imagePaths}
              duration={5} transition={2} />
            <div style={{ display: "flex", flex: 1, minHeight: "100vh", justifyContent: "center" }}>
              <div>
                <Button
                  style={{ position: "absolute", right: 0, margin: 5 }}
                  variant="contained"
                  color="primary"
                ><a style={{ color: "#fff", textDecoration: "none" }} href={"/results"} target="_blank">RESULTS</a></Button>
              </div>
              <div
                style={{
                  width: "60%",
                  display: "inline-flex",
                  alignSelf: "flex-end",
                  flexDirection: "column",
                }}
              >
                <ProrigoLogo />
                <h1 className="animate__animated animate__pulse animate__infinite" style={{ color: "#fff" }}>{message}</h1>
                {/* <a href={"/results"} target="_blank"><h1>RESULTS</h1></a> */}
              </div>
            </div>
          </>
          :
          <>
            <div style={{ position: "absolute", width: "100%" }}>
              <Loader
                totalTime={question && question.timer}
                onTimeUpdate={(time) => {
                  setElapsedTime(time);
                }}
              />
            </div>
            {
              !isLastQuestion
                ?
                <QuestionView
                  isSubmitted={() => {
                    setShowLogo(true);
                    setElapsedTime(0);
                  }}
                  questionObj={question}
                  elapsedTime={elapsedTime}
                  totalTime={question && question.timer}
                />
                :
                <ThankYou />
            }

          </>
        }
      </Grid>
    </Grid >
  );
};

export default QuizePage;
