import React from "react";
import { Route } from "react-router-dom";
import ResultView from "../resultView";
import SignIn from "../signIn";

const ClientRoutes = () => {
    return (
        <>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/results" component={ResultView} />
        </>
    );
};

export default ClientRoutes;
